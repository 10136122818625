@import url('../Constants.css');

/* Tablets ======================================================================================================================= */
@media only screen and (min-width: 768px) and (max-width: 1024px) {

    .desktop-footer-container {
        display: none;
    }

    .mobile-footer-container {
        display: none;
    }

    .tablet-footer-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        background-color: #121212;
        /* background-color: red; */
        /* height: 1080px; */
        height: fit-content;
        width: 100vw;
        /* margin: 0px 0px 0px 0px; */
        padding: 85px 0px 45px 0px;
    }

    .footer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        /* background-color: #121212; */
        height: 100%;
        width: 80%;
        /* margin: 0px 0px 0px 0px; */
        /* padding: 85px 85px 45px 85px; */
    }

    .footer-email-and-social-row {
        /* background-color: yellow; */
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        height: fit-content;
    }

    .footer-email-field-column {
        /* background-color: green; */
        display: flex;
        flex-direction: column;
        width: fit-content;
        height: fit-content;
    }

    .footer-social-field-column {
        /* background-color: green; */
        display: flex;
        flex-direction: column;
        width: fit-content;
        height: fit-content;
    }

    .footer-newsletter-text {
        color: #cae1b7;
        font-weight: bold;
        letter-spacing: 2px;
        font-size: var(--tablet-normal-font-size);
    }

    .footer-firm-name-hashtag {
        color: white;
        font-weight: bold;
        letter-spacing: 2.5px;
        font-size: var(--tablet-normal-font-size);
    }

    .footer-enter-email-text {
        color: white;
        width: 280px;
        line-height: 22px;
        /* font-weight: bold; */
        /* font-size: var(--tablet-normal-font-size); */
        word-spacing: 2px;
    }

    .footer-email-textfield {
        background-color: transparent;
        height: 50px;
        width: 60vw;
        border: 1px solid #bebebe;
        border-radius: 5px;
        padding: 10px;
        color: #bebebe;
        font-size: var(--tablet-normal-font-size);
        outline: none;
        margin: 23px 0px 50px 0px;
    }

    .footer-facebook-icon {
        color: white;
        margin: 0px 0px 0px 0px;
        cursor: pointer;
    }

    .footer-instagram-icon {
        color: white;
        margin: 0px 0px 0px 40px;
        cursor: pointer;
    }

    .footer-youtube-icon {
        color: white;
        margin: 0px 0px 0px 40px;
        cursor: pointer;
    }

    .footer-tiktok-icon {
        color: white;
        margin: 0px 0px 0px 40px;
        cursor: pointer;
    }

    .footer-whatsapp-icon {
        color: white;
        margin: 0px 0px 0px 40px;
        cursor: pointer;
    }

    .footer-contact-and-options-column {
        /* background-color: yellow; */
        display: flex;
        flex-direction: column;
        align-items: start;
        /* justify-content: space-between; */
        width: 100%;
        height: fit-content;
        margin: 0px 0px 0px 0px;
    }

    .footer-contact-column {
        display: flex;
        flex-direction: column;
        align-items: start;
        height: fit-content;
        width: fit-content;
    }

    .footer-contact-email-text {
        color: white;
        text-decoration: none;
    }

    .footer-contact-phone-text {
        color: white;
        text-decoration: none;
        font-size: var(--tablet-normal-font-size);
        margin: 10px 0px 0px 0px;
    }

    .transparent-button-footer-address {
        background-color: transparent;
        border: 1px solid white;
        border-radius: 5px;
        color: white;
        padding: 8px 16px;
        cursor: pointer;
        transition: background-color 0.3s, color 0.3s;
        margin: -5px 0px 40px 0px;
        font-size: var(--tablet-button-font-size);
        width: 180px;
    }

    .transparent-button {
        background-color: transparent;
        border: 1px solid white;
        border-radius: 5px;
        color: white;
        padding: 8px 16px;
        cursor: pointer;
        transition: background-color 0.3s, color 0.3s;
        margin: 15px 0px 0px 0px;
        font-size: var(--tablet-button-font-size);
    }

    .footer-options-row {
        /* background-color: green; */
        display: flex;
        flex-wrap: wrap;
        height: fit-content;
        width: 100%;
    }

    .footer-options-row-column {
        display: flex;
        flex-direction: column;
        height: fit-content;
        width: fit-content;
        margin: 0px 20vw 20px 0px;
    }

    .options-row-column-heading {
        color: white;
        margin: 0px 0px 15px 0px;
        letter-spacing: 1.5px;
        border-bottom: 1px solid white;
        padding-bottom: 5px;
    }

    .options-row-column-option {
        color: white;
        margin: 0px 0px 15px 0px;
        cursor: pointer;
    }

    .copyright-and-terms-column {
        /* background-color: yellow; */
        display: flex;
        flex-direction: column;
        align-items: start;
        /* justify-content: space-between; */
        width: 100%;
        height: fit-content;
        margin: 0px 0px 0px 0px;
    }

    .copyright-text {
        color: white;
        font-weight: bold;
        font-size: 12px;
        word-spacing: 2.3px;
        letter-spacing: 1px;
        margin: 40px 0px 0px 0px;
    }

    .terms-text {
        color: white;
        /* font-weight: bold; */
        font-size: 12px;
        cursor: pointer;
        word-spacing: 2.3px;
        letter-spacing: 1px;
        margin: 30px 0px 0px 0px;
    }
}



/* Mobile Devices ======================================================================================================================= */
@media only screen and (max-width: 767px) {

    .desktop-footer-container {
        display: none;
    }

    .tablet-footer-container {
        display: none;
    }

    .mobile-footer-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        background-color: #121212;
        /* background-color: red; */
        /* height: 650px; */
        height: fit-content;
        width: 100vw;
        /* margin: 0px 0px 0px 0px; */
        padding: 50px 0px 45px 0px;
    }

    .footer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        /* background-color: #121212; */
        height: 100%;
        width: 90%;
        /* margin: 0px 0px 0px 0px; */
        /* padding: 85px 85px 45px 85px; */
    }

    .footer-email-and-social-row {
        /* background-color: yellow; */
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        height: fit-content;
    }

    .footer-email-field-column {
        /* background-color: green; */
        display: flex;
        flex-direction: column;
        width: 100%;
        height: fit-content;
    }

    .footer-social-field-column {
        /* background-color: green; */
        display: flex;
        flex-direction: column;
        width: fit-content;
        height: fit-content;
    }

    .footer-newsletter-text {
        color: #cae1b7;
        font-weight: bold;
        letter-spacing: 2px;
        font-size: var(--mobile-normal-font-size);
    }

    .footer-firm-name-hashtag {
        color: white;
        font-weight: bold;
        letter-spacing: 2.5px;
        font-size: var(--mobile-normal-font-size);
    }

    .footer-enter-email-text {
        color: white;
        width: 280px;
        line-height: 22px;
        /* font-weight: bold; */
        /* font-size: var(--mobile-normal-font-size); */
        word-spacing: 2px;
    }

    .footer-email-textfield {
        background-color: transparent;
        height: 50px;
        width: 99.7%;
        border: 1px solid #bebebe;
        border-radius: 5px;
        padding: 10px 0px 10px 10px;
        color: #bebebe;
        font-size: var(--mobile-normal-font-size);
        outline: none;
        margin: 23px 0px 50px 0px;
    }

    .footer-facebook-icon {
        color: white;
        margin: 0px 0px 0px 0px;
        cursor: pointer;
    }

    .footer-instagram-icon {
        color: white;
        margin: 0px 0px 0px 40px;
        cursor: pointer;
    }

    .footer-youtube-icon {
        color: white;
        margin: 0px 0px 0px 40px;
        cursor: pointer;
    }

    .footer-tiktok-icon {
        color: white;
        margin: 0px 0px 0px 40px;
        cursor: pointer;
    }

    .footer-whatsapp-icon {
        color: white;
        margin: 0px 0px 0px 40px;
        cursor: pointer;
    }

    .footer-contact-and-options-column {
        /* background-color: yellow; */
        display: flex;
        flex-direction: column;
        align-items: start;
        /* justify-content: space-between; */
        width: 100%;
        height: fit-content;
        margin: 0px 0px 0px 0px;
    }

    .footer-contact-column {
        display: flex;
        flex-direction: column;
        align-items: start;
        height: fit-content;
        width: 100%;
    }

    .footer-contact-email-text {
        color: white;
        text-decoration: none;
    }

    .footer-contact-phone-text {
        color: white;
        text-decoration: none;
        font-size: var(--mobile-normal-font-size);
        margin: 10px 0px 0px 0px;
    }

    .transparent-button-address {
        background-color: transparent;
        border: 1px solid white;
        border-radius: 5px;
        color: white;
        /* padding: 8px 16px; */
        cursor: pointer;
        transition: background-color 0.3s, color 0.3s;
        margin: 0px 0px 40px 0px;
        font-size: var(--mobile-normal-font-size);
        height: 50px;
        width: 99.7%;
    }

    .transparent-button {
        background-color: transparent;
        border: 1px solid white;
        border-radius: 5px;
        color: white;
        /* padding: 8px 16px; */
        cursor: pointer;
        transition: background-color 0.3s, color 0.3s;
        margin: 15px 0px 0px 0px;
        font-size: var(--mobile-normal-font-size);
        height: 50px;
        width: 99.7%;
    }

    .footer-options-row {
        /* background-color: green; */
        display: flex;
        flex-direction: column;
        height: fit-content;
        width: 100%;
    }

    .footer-options-row-column {
        display: flex;
        flex-direction: column;
        height: fit-content;
        width: fit-content;
        margin: 0px 0px 20px 0px;
    }

    .options-row-column-heading {
        color: white;
        margin: 0px 0px 15px 0px;
        letter-spacing: 1.5px;
        border-bottom: 1px solid white;
        padding-bottom: 5px;
    }

    .options-row-column-option {
        color: white;
        margin: 0px 0px 15px 0px;
        cursor: pointer;
    }

    .copyright-and-terms-column {
        /* background-color: yellow; */
        display: flex;
        flex-direction: column;
        align-items: start;
        /* justify-content: space-between; */
        width: 100%;
        height: fit-content;
        margin: 0px 0px 0px 0px;
    }

    .copyright-text {
        color: white;
        font-weight: bold;
        font-size: 12px;
        word-spacing: 2.3px;
        letter-spacing: 1px;
        margin: 40px 0px 0px 0px;
    }

    .terms-text {
        color: white;
        /* font-weight: bold; */
        font-size: 12px;
        cursor: pointer;
        word-spacing: 2.3px;
        letter-spacing: 1px;
        margin: 30px 0px 0px 0px;
    }
}



/* Desktops ======================================================================================================================= */
@media only screen and (min-width: 1025px) {

    .desktop-footer-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        background-color: #121212;
        /* height: 600px; */
        height: fit-content;
        width: 100vw;
        /* margin: 0px 0px 0px 0px; */
        padding: 85px 0px 45px 0px;
    }


    .tablet-footer-container {
        display: none;
    }

    .mobile-footer-container {
        display: none;
    }

    .footer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: #121212;
        height: 100%;
        width: 89%;
        /* margin: 0px 0px 0px 0px; */
        /* padding: 85px 85px 45px 85px; */
    }

    .footer-email-and-social-row {
        /* background-color: yellow; */
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        height: fit-content;
    }

    .footer-email-field-column {
        /* background-color: green; */
        display: flex;
        flex-direction: column;
        width: fit-content;
        height: fit-content;
    }

    .footer-social-field-column {
        /* background-color: green; */
        display: flex;
        flex-direction: column;
        width: fit-content;
        height: fit-content;
    }

    .footer-newsletter-text {
        color: #cae1b7;
        font-weight: bold;
        letter-spacing: 2px;
        font-size: var(--desktop-normal-font-size);
    }

    .footer-firm-name-hashtag {
        color: white;
        font-weight: bold;
        letter-spacing: 2.5px;
        font-size: var(--desktop-normal-font-size);
    }

    .footer-enter-email-text {
        width: 280px;
        line-height: 22px;
        color: white;
        /* font-weight: bold; */
        /* font-size: var(--desktop-normal-font-size); */
        word-spacing: 2px;
    }

    .footer-email-textfield {
        background-color: transparent;
        height: 50px;
        width: 36vw;
        border: 1px solid #bebebe;
        border-radius: 5px;
        padding: 10px;
        color: #bebebe;
        font-size: var(--desktop-normal-font-size);
        outline: none;
        margin: 23px 0px 0px 0px;
    }

    .footer-facebook-icon {
        color: white;
        margin: 0px 0px 0px 0px;
        cursor: pointer;
    }

    .footer-instagram-icon {
        color: white;
        margin: 0px 0px 0px 30px;
        cursor: pointer;
    }

    .footer-youtube-icon {
        color: white;
        margin: 0px 0px 0px 30px;
        cursor: pointer;
    }

    .footer-tiktok-icon {
        color: white;
        margin: 0px 0px 0px 30px;
        cursor: pointer;
    }

    .footer-whatsapp-icon {
        color: white;
        margin: 0px 0px 0px 40px;
        cursor: pointer;
    }

    .footer-contact-and-options-row {
        /* background-color: yellow; */
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        height: fit-content;
        margin: 100px 0px 0px 0px;
    }

    .footer-contact-column {
        display: flex;
        flex-direction: column;
        align-items: start;
        height: fit-content;
        width: fit-content;
    }

    .footer-contact-email-text {
        color: white;
        text-decoration: none;
    }

    .footer-contact-phone-text {
        color: white;
        text-decoration: none;
        font-size: var(--desktop-normal-font-size);
        margin: 10px 0px 0px 0px;
    }

    .transparent-button-footer-address {
        background-color: transparent;
        border: 1px solid white;
        border-radius: 5px;
        color: white;
        padding: 8px 16px;
        cursor: pointer;
        width: 180px;
        transition: background-color 0.3s, color 0.3s;
        margin: 15px 0px 0px 0px;
        font-size: var(--desktop-button-font-size);
        height: 35px;
    }

    .transparent-button-footer {
        background-color: transparent;
        border: 1px solid white;
        border-radius: 5px;
        color: white;
        padding: 8px 16px;
        cursor: pointer;
        transition: background-color 0.3s, color 0.3s;
        margin: 15px 0px 0px 0px;
        font-size: var(--desktop-button-font-size);
        height: 35px;
    }

    .footer-options-row {
        /* background-color: green; */
        display: flex;
        flex-direction: row;
        height: fit-content;
        width: fit-content;
    }

    .footer-options-row-column {
        display: flex;
        flex-direction: column;
        height: fit-content;
        width: fit-content;
        margin: 0px 0px 0px 100px;
    }

    .options-row-column-heading {
        color: white;
        margin: 0px 0px 15px 0px;
        letter-spacing: 1.5px;
        border-bottom: 1px solid white;
        padding-bottom: 5px;
    }

    .options-row-column-option {
        color: white;
        margin: 0px 0px 15px 0px;
        cursor: pointer;
    }

    .copyright-and-terms-row {
        /* background-color: yellow; */
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        height: fit-content;
        margin: 80px 0px 0px 0px;
    }

    .copyright-text {
        color: white;
        font-weight: bold;
        font-size: 12px;
        word-spacing: 2.3px;
        letter-spacing: 1px;
    }

    .terms-row {
        /* background-color: yellow; */
        display: flex;
        flex-direction: row;
        width: fit-content;
        height: fit-content;
    }

    .terms-text {
        color: white;
        /* font-weight: bold; */
        cursor: pointer;
        font-size: 12px;
        word-spacing: 2.3px;
        letter-spacing: 1px;
        margin: 0px 0px 0px 40px;
    }
}