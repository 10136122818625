.b-parent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
}

.b-at-cl {
    /* background-color: red; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 30%;
    width: 15%;
}

.b-at-rw {
    /* background-color: green; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.at-button-1 {
    height: 50px;
    width: 50px;
    font-size: 40px;
}

.at-button-2 {
    height: 50px;
    width: 100%;
    font-size: 20px;
}

.the-display {
    font-size: 40px;
}