/* Tablets ======================================================================================================================= */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .blog-details-pg {
    /* background-color: green; */
  }

  .blog-details {
    padding: 20px;
    max-width: 900px;
    width: 80%;
    margin: 0 auto;
  }

  .blog-title {
    font-size: 2em;
    margin-bottom: 20px;
  }

  .blog-image {
    max-width: 80%;
    /* Adjust the max-width to make the image smaller */
    height: auto;
    margin-bottom: 20px;
    display: block;
    /* Center the image */
    margin-left: auto;
    margin-right: auto;
  }

  .blog-section {
    margin-bottom: 20px;
  }

  .blog-section-heading {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .blog-section-content {
    font-size: 1em;
    line-height: 1.6;
  }

  .space-between-navbar-bl {
    height: 120px;
  }

  .blog-content p {
    margin-bottom: 1em;
    font-size: 1em;
    /* Default font size */
    line-height: 1.6;
  }

  /* Font sizes */
  .blog-content .ql-size-small {
    font-size: 0.75em;
    /* Small font size */
  }

  .blog-content .ql-size-large {
    font-size: 1.5em;
    /* Large font size */
  }

  .blog-content .ql-size-huge {
    font-size: 2.5em;
    /* Huge font size */
  }

  /* Font colors */
  .blog-content .ql-color-red {
    color: red;
  }

  .blog-content .ql-color-blue {
    color: blue;
  }

  .blog-content .ql-color-green {
    color: green;
  }

  /* Background colors */
  .blog-content .ql-background-yellow {
    background-color: yellow;
  }

  .blog-content .ql-background-gray {
    background-color: gray;
  }

  .blog-content .ql-background-pink {
    background-color: pink;
  }

  /* Links */
  .blog-content a {
    color: #007bff;
    text-decoration: none;
  }

  .blog-content a:hover {
    text-decoration: underline;
  }

}

/* Mobile Devices ======================================================================================================================= */
@media only screen and (max-width: 767px) {
  .blog-details-pg {
    /* background-color: blue; */
  }

  .blog-details {
    padding: 20px;
    max-width: 900px;
    width: 90%;
    margin: 0 auto;
  }

  .blog-title {
    font-size: 2em;
    margin-bottom: 20px;
  }

  .blog-image {
    max-width: 80%;
    /* Adjust the max-width to make the image smaller */
    height: auto;
    margin-bottom: 20px;
    display: block;
    /* Center the image */
    margin-left: auto;
    margin-right: auto;
  }

  .blog-section {
    margin-bottom: 20px;
  }

  .blog-section-heading {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .blog-section-content {
    font-size: 1em;
    line-height: 1.6;
  }

  .space-between-navbar-bl {
    height: 120px;
  }

  .blog-content p {
    margin-bottom: 1em;
    font-size: 1em;
    /* Default font size */
    line-height: 1.6;
  }

  /* Font sizes */
  .blog-content .ql-size-small {
    font-size: 0.75em;
    /* Small font size */
  }

  .blog-content .ql-size-large {
    font-size: 1.5em;
    /* Large font size */
  }

  .blog-content .ql-size-huge {
    font-size: 2.5em;
    /* Huge font size */
  }

  /* Font colors */
  .blog-content .ql-color-red {
    color: red;
  }

  .blog-content .ql-color-blue {
    color: blue;
  }

  .blog-content .ql-color-green {
    color: green;
  }

  /* Background colors */
  .blog-content .ql-background-yellow {
    background-color: yellow;
  }

  .blog-content .ql-background-gray {
    background-color: gray;
  }

  .blog-content .ql-background-pink {
    background-color: pink;
  }

  /* Links */
  .blog-content a {
    color: #007bff;
    text-decoration: none;
  }

  .blog-content a:hover {
    text-decoration: underline;
  }

}

/* Desktops ======================================================================================================================= */
@media only screen and (min-width: 1025px) {
  .blog-details-pg {
    /* background-color: red; */
  }

  .blog-details {
    padding: 20px;
    max-width: 900px;
    width: 90%;
    margin: 0 auto;
  }

  .blog-title {
    font-size: 2em;
    margin-bottom: 20px;
  }

  .blog-image {
    max-width: 80%;
    /* Adjust the max-width to make the image smaller */
    height: auto;
    margin-bottom: 20px;
    display: block;
    /* Center the image */
    margin-left: auto;
    margin-right: auto;
  }

  .blog-section {
    margin-bottom: 20px;
  }

  .blog-section-heading {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .blog-section-content {
    font-size: 1em;
    line-height: 1.6;
  }

  .space-between-navbar-bl {
    height: 120px;
  }

  .blog-content p {
    margin-bottom: 1em;
    font-size: 1em;
    /* Default font size */
    line-height: 1.6;
  }

  /* Font sizes */
  .blog-content .ql-size-small {
    font-size: 0.75em;
    /* Small font size */
  }

  .blog-content .ql-size-large {
    font-size: 1.5em;
    /* Large font size */
  }

  .blog-content .ql-size-huge {
    font-size: 2.5em;
    /* Huge font size */
  }

  /* Font colors */
  .blog-content .ql-color-red {
    color: red;
  }

  .blog-content .ql-color-blue {
    color: blue;
  }

  .blog-content .ql-color-green {
    color: green;
  }

  /* Background colors */
  .blog-content .ql-background-yellow {
    background-color: yellow;
  }

  .blog-content .ql-background-gray {
    background-color: gray;
  }

  .blog-content .ql-background-pink {
    background-color: pink;
  }

  /* Links */
  .blog-content a {
    color: #007bff;
    text-decoration: none;
  }

  .blog-content a:hover {
    text-decoration: underline;
  }
}