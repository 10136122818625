@import url('../Constants.css');

/* Tablets ======================================================================================================================= */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .founder-image-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* backgroundColor: lightBlue, */
        width: 380px;
        height: auto;
        margin: 0px 0px 0px 70px;
    }

    .about-scars-main-container-tablet {
        /* background-color: red; */
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: fit-content;
        width: 100vw;
        margin: 0px 0px 150px 0px;
    }

    .about-scars-main-container-desktop {
        display: none;
    }

    .about-scars-main-container-mobile {
        display: none;
    }

    .about-scars-row {
        /* background-color: yellow; */
        display: flex;
        flex-direction: row;
        /* justify-content: space-between; */
        height: fit-content;
        width: 80%;
    }

    .about-scars-left-side {
        /* background-color: green; */
        width: 100%;
        height: fit-content;
    }

    .about-scars-right-side {
        /* background-color: green; */
        display: flex;
        flex-direction: column;
        width: 100%;
        height: fit-content;
    }


    .about-scars-left-about {
        /* color: #023273; */
        /* font-size: 30px; */
        font-weight: bold;
        letter-spacing: 1px;
    }

    .about-scars-left-heading {
        /* color: #023273; */
        font-size: var(--tablet-heading-font-size2);
        font-weight: bold;
    }

    .about-scars-left-desc {
        font-size: var(--tablet-normal-font-size);
        line-height: 24px;
        margin: 20px 0px 0px 0px;
    }

    .view-all-about-scars-button {
        background-color: #023273;
        color: #ffffff;
        /* padding: 10px 20px; */
        border-radius: 10px;
        border: none;
        cursor: pointer;
        font-size: var(--tablet-button-font-size);
        font-weight: bold;
        /* height: 45px; */
        height: 55px;
        width: 200px;
        margin: 70px 0px 0px 0px;
    }
}

/* Mobile Devices ======================================================================================================================= */
@media only screen and (max-width: 767px) {
    .founder-image-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* backgroundColor: lightBlue, */
        width: 90vw;
        height: auto;
        margin: 40px 0px 0px 0px;
    }

    .about-scars-main-container-tablet {
        /* background-color: red; */
        display: none;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: fit-content;
        width: 100vw;
        margin: 0px 0px 150px 0px;
    }

    .about-scars-main-container-mobile {
        /* background-color: red; */
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: fit-content;
        width: 100vw;
        margin: 0px 0px 150px 0px;
    }

    .about-scars-main-container-desktop {
        display: none;
    }

    .about-scars-row {
        /* background-color: yellow; */
        display: flex;
        flex-direction: column;
        /* justify-content: space-between; */
        height: fit-content;
        width: 90%;
    }

    .about-scars-left-side {
        /* background-color: green; */
        width: 100%;
        height: fit-content;
    }

    .about-scars-right-side {
        /* background-color: green; */
        display: flex;
        flex-direction: column;
        width: 100%;
        height: fit-content;
    }


    .about-scars-left-about {
        /* color: #023273; */
        /* font-size: 30px; */
        font-weight: bold;
        letter-spacing: 1px;
    }

    .about-scars-left-heading {
        /* color: #023273; */
        font-size: var(--mobile-heading-font-size2);
        font-weight: bold;
    }

    .about-scars-left-desc {
        font-size: var(--mobile-normal-font-size);
        line-height: 24px;
        margin: 20px 0px 0px 0px;
    }

    .view-all-about-scars-button {
        background-color: #023273;
        color: #ffffff;
        /* padding: 10px 20px; */
        border-radius: 10px;
        border: none;
        cursor: pointer;
        font-size: var(--mobile-button-font-size);
        font-weight: bold;
        /* height: 45px; */
        height: 55px;
        width: 100%;
        margin: 40px 0px 0px 0px;
    }
}

/* Desktops ======================================================================================================================= */
@media only screen and (min-width: 1025px) {
    .founder-image-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* background-color: lightBlue; */
        width: 300px;
        height: auto;
        margin: 0px 0px 0px 0px;
    }

    .about-scars-main-container-desktop {
        /* background-color: red; */
        display: flex;
        flex-direction: row;
        justify-content: center;
        height: fit-content;
        width: 100vw;
        margin: 0px 0px 150px 0px;
    }

    .about-scars-main-container-tablet {
        display: none;
    }

    .about-scars-main-container-mobile {
        display: none;
    }

    .about-scars-row {
        /* background-color: yellow; */
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: fit-content;
        width: 89.5%;
    }

    .about-scars-left-side {
        /* background-color: green; */
        width: 100%;
        height: fit-content;
    }


    .about-scars-left-about {
        /* color: #023273; */
        /* font-size: 30px; */
        font-weight: bold;
        letter-spacing: 1px;
    }

    .about-scars-left-heading {
        /* color: #023273; */
        font-size: var(--desktop-heading-font-size2);
        font-weight: bold;
    }

    .about-scars-left-desc {
        font-size: var(--desktop-normal-font-size);
        line-height: 24px;
        margin: 20px 0px 0px 0px;
    }

    .view-all-about-scars-button {
        background-color: #023273;
        color: #ffffff;
        /* padding: 10px 20px; */
        border-radius: 10px;
        border: none;
        cursor: pointer;
        font-size: var(--desktop-button-font-size);
        font-weight: bold;
        /* height: 45px; */
        height: 55px;
        width: 55%;
        margin: 70px 0px 0px 0px;
    }
}