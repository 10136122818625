@import url('../Constants.css');

/* Desktops ======================================================================================================================= */
@media only screen and (min-width: 1025px) {

    /* main column that holds the upper text and form....... */
    .consultation-form-main-column {
        /* display: black; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0px 0px 0px 0px;
    }

    .consult-text-1 {
        font-size: var(--desktop-normal-font-size);
        letter-spacing: 1px;
        font-weight: bold;
        margin: 0px 0px 0px 0px;
    }

    .consult-text-2 {
        font-size: var(--desktop-heading-font-size2);
        word-spacing: 2px;
        font-weight: bold;
    }

    .consult-text-3 {
        font-size: var(--desktop-normal-font-size);
        letter-spacing: 1px;
        margin: 30px 0px 30px 0px;
        text-align: center;
    }

    .consultation-card {
        background-color: white;
        margin: 20px 0px 0px 0px;
        padding: 30px;
        border-radius: 8px;
        box-shadow: 0 0 20px #c9c9c95c;
        width: 89%;
        height: fit-content;
    }

    .in-card-row {
        margin: 20px 50px 50px 50px;
        /* background-color: green; */
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: start;
        height: 100%;
        width: auto;
    }

    .form-column {
        /* background-color: red; */
        display: flex;
        flex-direction: column;
        justify-self: center;
        align-items: center;
        height: 100%;
        width: 60%;
        margin: 0px 30px 0px 0px;
    }

    .the-form {
        width: 100%;
    }

    .input-row {
        margin: 0px 0px 0px 0px;
        display: flex;
        justify-content: space-between;
    }

    .input-row-inner-column {
        display: flex;
        flex-direction: column;
    }

    .input-row-inner-column-1 {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-right: 18px;
    }

    .input-row-inner-column-2 {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-right: 0;
    }

    .input-label {
        margin: 20px 0px 5px 0px;
    }

    .text-field-input {
        border: 1px solid #e8e7e7;
        border-radius: 4px;
        padding: 12px;
        background-color: #f0f0f0;
        width: 100%;
        outline: none;
        height: 48px;
        resize: vertical;
    }

    .submit-container {
        width: 100%;
    }

    .submit-button {
        background-color: #023273;
        color: #ffffff;
        /* padding: 10px 20px; */
        border-radius: 10px;
        border: none;
        cursor: pointer;
        font-size: var(--desktop-button-font-size);
        font-weight: bold;
        /* height: 45px; */
        height: 55px;
        width: 100%;
        margin: 30px 0px 0px 0px;
    }

    .text-field-subject {
        border: 1px solid #e8e7e7;
        border-radius: 4px;
        padding: 12px;
        background-color: #f0f0f0;
        height: 100px;
        width: 100%;
        outline: none;
        resize: vertical;
        /* Allow vertical resize only */
    }

    /* right side of the row ............................................... */

    .consultation-types-section {
        /* background-color: orange; */
        display: flex;
        flex-direction: row;
        justify-content: end;
        align-items: start;
        height: 100%;
        width: 35%;
    }

    .consultation-types-column {
        /* background-color: pink; */
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;
        /* height: 100%; */
        width: 80%;
        margin: 42px 0px 0px 0px;
    }

    .consultation-types-title {
        font-size: var(--desktop-normal-font-size);
        word-spacing: 2px;
        margin: 0px 0px 30px 0px;
        font-weight: bold;
    }

    .consultation-bullet-row {
        display: flex;
        flex-direction: row;
        margin: 12px 0px 25px 0px
    }

    .consultation-bullet-circle {
        display: flex;
        flex-direction: column;
        height: 33px;
        width: 33px;
        background-color: #023273;
        border-radius: 50px;
        justify-content: center;
        align-items: center;
    }

    .consultation-bullet-number {
        color: white
    }

    .consultation-bullet-text {
        width: 18vw;
        margin: 0px 0px 0px 20px;
        font-size: var(--desktop-normal-font-size);
        line-height: 18px;
    }

    .dotted-line-row {
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: center;
        height: fit-content;
        width: 100%;
    }

    .dotted-line {
        border-top: 1px dashed #999;
        margin: 8px 0px 8px 0px;
        height: fit-content;
        width: 100%;
        max-width: 510px
    }
}

/* Tablets ======================================================================================================================== */
@media only screen and (min-width: 768px) and (max-width: 1024px) {

    /* main column that holds the upper text and form....... */
    .consultation-form-main-column {
        /* display: black; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0px 0px 0px 0px;
    }

    .consult-text-1 {
        font-size: var(--tablet-normal-font-size);
        letter-spacing: 1px;
        font-weight: bold;
        margin: 0px 0px 0px 0px;
        width: 80%;
        text-align: center;
    }

    .consult-text-2 {
        font-size: var(--tablet-heading-font-size2);
        word-spacing: 2px;
        font-weight: bold;
        width: 80%;
        text-align: center;
    }

    .consult-text-3 {
        font-size: var(--tablet-normal-font-size);
        letter-spacing: 1px;
        margin: 30px 0px 30px 0px;
        text-align: center;
        width: 80%;
        text-align: center;
    }

    .consultation-card {
        background-color: white;
        margin: 20px 0px 0px 0px;
        padding: 30px;
        border-radius: 8px;
        box-shadow: 0 0 20px #c9c9c95c;
        width: 80%;
        height: fit-content;
    }

    .in-card-row {
        margin: 20px 20px 20px 20px;
        /* background-color: green; */
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: start;
        height: 100%;
        width: auto;
    }

    .form-column {
        /* background-color: red; */
        display: flex;
        flex-direction: column;
        justify-self: center;
        align-items: center;
        height: 100%;
        width: 100%;
        margin: 0px 0px 0px 0px;
    }

    .the-form {
        width: 100%;
    }

    .input-row {
        margin: 0px 0px 0px 0px;
        display: flex;
        flex-direction: column;

    }

    .input-row-inner-column {
        display: flex;
        flex-direction: column;
    }

    .input-row-inner-column-1 {
        display: flex;
        flex-direction: column;
    }

    .input-row-inner-column-2 {
        display: flex;
        flex-direction: column;
    }

    .input-label {
        margin: 20px 0px 5px 0px;
    }

    .text-field-input {
        border: 1px solid #e8e7e7;
        border-radius: 4px;
        padding: 12px;
        background-color: #f0f0f0;
        width: 100%;
        outline: none;
        height: 48px;
        resize: vertical;
    }

    .submit-container {
        width: 100%;
    }

    .submit-button {
        background-color: #023273;
        color: #ffffff;
        /* padding: 10px 20px; */
        border-radius: 10px;
        border: none;
        cursor: pointer;
        font-size: var(--tablet-button-font-size);
        font-weight: bold;
        /* height: 45px; */
        height: 55px;
        width: 100%;
        margin: 50px 0px 0px 0px;
    }

    .text-field-subject {
        border: 1px solid #e8e7e7;
        border-radius: 4px;
        padding: 12px;
        background-color: #f0f0f0;
        height: 100px;
        width: 100%;
        outline: none;
        resize: vertical;
        /* Allow vertical resize only */
    }

    /* right side of the row ............................................... */

    .consultation-types-section {
        /* background-color: orange; */
        display: none;
        flex-direction: row;
        justify-content: end;
        align-items: start;
        height: 100%;
        width: 35%;
    }

    .consultation-types-column {
        /* background-color: pink; */
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;
        /* height: 100%; */
        margin: 42px 0px 0px 0px;
    }

    .consultation-types-title {
        font-size: var(--tablet-normal-font-size);
        word-spacing: 2px;
        margin: 0px 0px 30px 0px;
        font-weight: bold;
    }

    .consultation-bullet-row {
        display: flex;
        flex-direction: row;
        margin: 12px 0px 25px 0px
    }

    .consultation-bullet-circle {
        display: flex;
        flex-direction: column;
        height: 33px;
        width: 33px;
        background-color: #023273;
        border-radius: 50px;
        justify-content: center;
        align-items: center;
    }

    .consultation-bullet-number {
        color: white
    }

    .consultation-bullet-text {
        width: 18vw;
        margin: 0px 0px 0px 20px;
        font-size: var(--tablet-normal-font-size);
        line-height: 18px;
    }

    .dotted-line-row {
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: center;
        height: fit-content;
        width: 100%;
    }

    .dotted-line {
        border-top: 1px dashed #999;
        margin: 8px 0px 8px 0px;
        height: fit-content;
        width: 100%;
        max-width: 510px
    }
}

/* Mobile Devices ================================================================================================================= */
@media only screen and (max-width: 767px) {

    /* main column that holds the upper text and form....... */
    .consultation-form-main-column {
        /* display: black; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0px 0px 0px 0px;
    }

    .consult-text-1 {
        font-size: var(--mobile-normal-font-size);
        letter-spacing: 1px;
        font-weight: bold;
        margin: 0px 0px 0px 0px;
        width: 90%;
        text-align: center;
    }

    .consult-text-2 {
        font-size: var(--mobile-heading-font-size2);
        word-spacing: 2px;
        font-weight: bold;
        width: 90%;
        text-align: center;
    }

    .consult-text-3 {
        font-size: var(--mobile-normal-font-size);
        letter-spacing: 1px;
        margin: 30px 0px 30px 0px;
        text-align: center;
        width: 90%;
        text-align: center;
    }

    .consultation-card {
        background-color: white;
        margin: 20px 0px 0px 0px;
        padding: 30px;
        border-radius: 8px;
        box-shadow: 0 0 20px #c9c9c95c;
        width: 89%;
        height: fit-content;
    }

    .in-card-row {
        margin: 0px 0px 0px 0px;
        /* background-color: green; */
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: start;
        height: 100%;
        width: auto;
    }

    .form-column {
        /* background-color: red; */
        display: flex;
        flex-direction: column;
        justify-self: center;
        align-items: center;
        height: 100%;
        width: 100%;
        margin: 0px 0px 0px 0px;
    }

    .the-form {
        width: 100%;
    }

    .input-row {
        margin: 0px 0px 0px 0px;
        display: flex;
        flex-direction: column;

    }

    .input-row-inner-column {
        display: flex;
        flex-direction: column;
    }

    .input-row-inner-column-1 {
        display: flex;
        flex-direction: column;
    }

    .input-row-inner-column-2 {
        display: flex;
        flex-direction: column;
    }

    .input-label {
        margin: 20px 0px 5px 0px;
    }

    .text-field-input {
        border: 1px solid #e8e7e7;
        border-radius: 4px;
        padding: 12px;
        background-color: #f0f0f0;
        width: 100%;
        outline: none;
        height: 48px;
        resize: vertical;
    }

    .submit-container {
        width: 100%;
    }

    .submit-button {
        background-color: #023273;
        color: #ffffff;
        /* padding: 10px 20px; */
        border-radius: 10px;
        border: none;
        cursor: pointer;
        font-size: var(--mobile-button-font-size);
        font-weight: bold;
        /* height: 45px; */
        height: 55px;
        width: 100%;
        margin: 50px 0px 0px 0px;
    }

    .text-field-subject {
        border: 1px solid #e8e7e7;
        border-radius: 4px;
        padding: 12px;
        background-color: #f0f0f0;
        height: 100px;
        width: 100%;
        outline: none;
        resize: vertical;
        /* Allow vertical resize only */
    }

    /* right side of the row ............................................... */

    .consultation-types-section {
        /* background-color: orange; */
        display: none;
        flex-direction: row;
        justify-content: end;
        align-items: start;
        height: 100%;
        width: 35%;
    }

    .consultation-types-column {
        /* background-color: pink; */
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;
        /* height: 100%; */
        margin: 42px 0px 0px 0px;
    }

    .consultation-types-title {
        font-size: var(--mobile-normal-font-size);
        word-spacing: 2px;
        margin: 0px 0px 30px 0px;
        font-weight: bold;
    }

    .consultation-bullet-row {
        display: flex;
        flex-direction: row;
        margin: 12px 0px 25px 0px
    }

    .consultation-bullet-circle {
        display: flex;
        flex-direction: column;
        height: 33px;
        width: 33px;
        background-color: #023273;
        border-radius: 50px;
        justify-content: center;
        align-items: center;
    }

    .consultation-bullet-number {
        color: white
    }

    .consultation-bullet-text {
        width: 18vw;
        margin: 0px 0px 0px 20px;
        font-size: var(--mobile-normal-font-size);
        line-height: 18px;
    }

    .dotted-line-row {
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: center;
        height: fit-content;
        width: 100%;
    }

    .dotted-line {
        border-top: 1px dashed #999;
        margin: 8px 0px 8px 0px;
        height: fit-content;
        width: 100%;
        max-width: 510px
    }
}