/* Tablets ======================================================================================================================= */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .sfw-space-between-navbar {
        height: 61px;
    }
}

/* Mobile Devices ======================================================================================================================= */
@media only screen and (max-width: 767px) {
    .sfw-space-between-navbar {
        height: 61px;
    }
}

/* Desktops ======================================================================================================================= */
@media only screen and (min-width: 1025px) {
    .sfw-space-between-navbar {
        height: 60px;
    }
}