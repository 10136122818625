@import url('../Constants.css');

/* Tablets ======================================================================================================================= */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .why-number-1-section {
    /* background-color: red; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0px 0px 0px 0px;
  }

  .number-1-inner-column {
    /* background-color: green; */
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    width: 80vw;
  }

  .number-1-text {
    font-size: var(--tablet-heading-font-size2);
    font-weight: bold;
    padding: 14px 25px 10px 0px;
  }

  .inner-row {
    /* background-color: yellow; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* width: 100%; */
  }

  .video-container {
    /* background-color: red; */
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 40vw;
    width: 80vw;
    min-height: 450px;
    border-radius: 10px;
    margin: 0px 0px 10px 0px
  }

  .video-component {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }

  .video-component-overlay {
    background-color: rgba(4, 1, 1, 0.5);
    border-radius: 10px;
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%
  }

  .number-bullet-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 25px 10px 0px
  }

  .num-bullet-number {
    font-size: var(--tablet-heading-font-size2);
    padding: 0px 20px 0px 0px;
  }

  .num-bullet-text {
    line-height: 1.4;
    font-size: var(--tablet-normal-font-size);
  }

  .dlr {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    height: fit-content;
    width: 100%;
  }

  .dotted-line-wn {
    border-top: 1px dashed #999;
    margin: 8px 0px 8px 0px;
    height: fit-content;
    width: 100%;
  }

  .quoteButton {
    background-color: #023273;
    /* background-color: orange; */
    color: #ffffff;
    padding: 10px 20px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    font-size: var(--tablet-button-font-size);
    font-weight: bold;
    height: 55px;
    width: 270px;
    margin: 20px 25px 0px 0px;
  }
}

/* Mobile Devices ======================================================================================================================= */
@media only screen and (max-width: 767px) {

  .why-number-1-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0px 0px 0px 0px;
  }

  .number-1-inner-column {
    /* background-color: green; */
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    /* width: 100% */
    width: 90vw;

  }

  .number-1-text {
    font-size: var(--mobile-heading-font-size2);
    font-weight: bold;
    padding: 14px 0px 10px 0px;
  }

  .inner-row {
    /* background-color: red; */
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    /* width: 100%; */
  }

  .video-container {
    position: relative;
    height: 65vw;
    width: 90vw;
    /* min-height: 450px; */
    border-radius: 10px;
    margin: 0px 0px 10px 0px
  }

  .video-component {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }

  .video-component-overlay {
    background-color: rgba(4, 1, 1, 0.5);
    border-radius: 10px;
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%
  }

  .quoteButton {
    background-color: #023273;
    color: #ffffff;
    padding: 10px 20px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    font-size: var(--mobile-button-font-size);
    font-weight: bold;
    height: 55px;
    width: 100%;
    margin: 20px 0px 0px 0px;
  }

  .number-bullet-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 0px 10px 0px
  }

  .num-bullet-number {
    font-size: var(--mobile-heading-font-size2);
    padding: 0px 20px 0px 0px
  }

  .num-bullet-text {
    line-height: 1.4;
    font-size: var(--mobile-normal-font-size);
  }

  .dlr {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    height: fit-content;
    width: 100%;
  }

  .dotted-line-wn {
    border-top: 1px dashed #999;
    margin: 8px 0px 8px 0px;
    height: fit-content;
    width: 100%;
  }
}

/* Desktops ======================================================================================================================= */
@media only screen and (min-width: 1025px) {
  .why-number-1-section {
    /* background-color: red; */
    margin: 0px 0px 0px 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }

  .inner-row {
    /* background-color: red; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 89%;
  }

  .number-1-inner-column {
    /* background-color: red; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    width: 40%;
    margin: 0px 0px 10px 25px
  }

  .number-1-text {
    font-size: var(--desktop-heading-font-size2);
    font-weight: bold;
    padding: 0px 0px 30px 0px;
  }

  .video-container {
    position: relative;
    height: 34vw;
    width: 42vw;
    min-height: 450px;
    border-radius: 10px;
    margin: 0px 25px 0px 0px
  }

  .video-component {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }

  .video-component-overlay {
    background-color: rgba(4, 1, 1, 0.5);
    border-radius: 10px;
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%
  }

  .quoteButton {
    background-color: #023273;
    color: #ffffff;
    padding: 10px 20px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    font-size: var(--desktop-button-font-size);
    font-weight: bold;
    height: 55px;
    width: 270px;
    margin: 35px 25px 0px 0px;
  }

  .number-bullet-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 25px 10px 0px
  }

  .num-bullet-number {
    font-size: 40px;
    padding: 0px 20px 0px 0px
  }

  .num-bullet-text {
    line-height: 1.4;
    font-size: var(--desktop-normal-font-size);
  }

  .dlr {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    height: fit-content;
    width: 100%;
  }

  .dotted-line-wn {
    border-top: 1px dashed #999;
    margin: 1px 0px 8px 0px;
    height: fit-content;
    width: 100%;
  }
}