@import url('../Constants.css');

/* Tablets ======================================================================================================================= */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .home-scars-video-container {
        position: relative;
        height: 720px;
        width: 100vw;
        overflow: hidden;
        margin: 0px 0px 0px 0px;
    }

    .home-scars-video-container-mobile {
        display: none;
    }

    .space-between-navbar {
        height: 60px;
    }

    .home-scars-video-component {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .home-scars-video-overlay {
        background-color: rgba(4, 1, 1, 0.5);
        position: absolute;
        top: 0;
        left: 0;
        color: white;
        height: 720px;
        width: 100vw;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: end;
        margin: 0px 0px 150px 0px;
    }

    .home-scars-video-overlay-column {
        /* background-color: red; */
        width: 80%;
        display: flex;
        flex-direction: column;
        /* position: absolute; */
        bottom: 0;
        left: 0;
        margin: 0px 0px 79px 0px;
    }

    .home-scars-video-overlay-column-text1 {
        color: white;
        font-size: var(--tablet-heading-font-size1);
        font-weight: bold
    }

    .home-scars-video-overlay-column-text2 {
        color: #a7d8f7;
        font-size: var(--tablet-heading-font-size1);
        font-weight: bold;
        line-height: 35px
    }

    .home-scars-video-overlay-column-text3 {
        color: white;
        font-size: var(--tablet-normal-font-size);
        font-weight: bold;
        width: 100%;
        margin: 25px 0px 0px 0px
    }

    .quoteButtonHomeAlopecia {
        background-color: #023273;
        color: white;
        padding: 10px 20px;
        border-radius: 10px;
        border: none;
        cursor: pointer;
        font-size: var(--tablet-button-font-size);
        font-weight: bold;
        height: 55px;
        width: 270px;
        margin: 35px 25px 0px 0px;
    }
}

/* Mobile Devices ======================================================================================================================= */
@media only screen and (max-width: 767px) {
    .home-scars-video-container {
        display: none;
    }

    .home-scars-video-container-mobile {
        height: 720px;
        width: 100vw;
        /* overflow: hidden; */
        margin: 0px 0px 0px 0px;
    }

    .space-between-navbar {
        height: 60px;
    }

    .home-scars-video-component {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .home-scars-video-overlay {
        background-color: rgba(4, 1, 1, 0.5);
        position: absolute;
        top: 0;
        left: 0;
        color: white;
        height: 720px;
        width: 100vw;
        margin: 60px 0px 150px 0px;
    }

    .home-scars-video-overlay-column {
        /* background-color: yellow; */
        display: flex;
        flex-direction: column;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        margin: 0px 0px 25px 0px;
    }

    .home-scars-video-overlay-column-text1 {
        color: white;
        font-size: var(--mobile-heading-font-size1);
        font-weight: bold
    }

    .home-scars-video-overlay-column-text2 {
        color: #a7d8f7;
        font-size: var(--mobile-heading-font-size1);
        font-weight: bold;
        line-height: 35px
    }

    .home-scars-video-overlay-column-text3 {
        color: white;
        font-size: var(--mobile-normal-font-size);
        font-weight: bold;
        width: 85vw;
        margin: 25px 0px 0px 0px
    }

    .quoteButtonHomeAlopecia {
        background-color: #023273;
        color: white;
        padding: 10px 20px;
        border-radius: 10px;
        border: none;
        cursor: pointer;
        font-size: var(--mobile-button-font-size);
        font-weight: bold;
        height: 55px;
        width: 100%;
        margin: 30px 0px 0px 0px;
    }
}

/* Desktops ======================================================================================================================= */
@media only screen and (min-width: 1025px) {
    .home-scars-video-container {
        height: 720px;
        width: 100vw;
        overflow: hidden;
        margin: 0px 0px 0px 0px;
    }

    .home-scars-video-container-mobile {
        display: none;
    }

    .space-between-navbar {
        height: 60px;
    }

    .home-scars-video-component {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .home-scars-video-overlay {
        background-color: rgba(4, 1, 1, 0.5);
        position: absolute;
        top: 0;
        left: 0;
        color: white;
        height: 720px;
        width: 100vw;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: end;
        margin: 60px 0px 150px 0px;
    }

    .home-scars-video-overlay-column {
        width: 90%;
        display: flex;
        flex-direction: column;
        /* position: absolute; */
        bottom: 0;
        left: 0;
        margin: 0px 0px 79px 0px;
    }

    .home-scars-video-overlay-column-text1 {
        color: white;
        font-size: var(--desktop-heading-font-size1);
        font-weight: bold
    }

    .home-scars-video-overlay-column-text2 {
        color: #a7d8f7;
        font-size: var(--desktop-heading-font-size1);
        font-weight: bold;
        line-height: 70px
    }

    .home-scars-video-overlay-column-text3 {
        color: white;
        font-size: var(--desktop-normal-font-size);
        font-weight: bold;
        width: 840px;
        margin: 30px 0px 0px 0px
    }

    .quoteButtonHomeAlopecia {
        background-color: #023273;
        color: white;
        padding: 10px 20px;
        border-radius: 10px;
        border: none;
        cursor: pointer;
        font-size: var(--desktop-button-font-size);
        font-weight: bold;
        height: 55px;
        width: 270px;
        margin: 35px 25px 0px 0px;
    }
}