@import url('../Constants.css');

/* Tablets ======================================================================================================================= */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .faq-main-container-tablet {
        /* background-color: red; */
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: fit-content;
        width: 100vw;
        margin: 0px 0px 0px 0px;
    }

    .faq-main-container-desktop {
        display: none;
    }

    .faq-row {
        /* background-color: yellow; */
        display: flex;
        flex-direction: column;
        /* justify-content: space-between; */
        height: fit-content;
        width: 80%;
    }

    .faq-left-side {
        /* background-color: green; */
        width: 100%;
        height: fit-content;
    }

    .faq-right-side {
        /* background-color: green; */
        display: flex;
        flex-direction: column;
        width: 100%;
        height: fit-content;
    }


    .faq-left-faq {
        color: #023273;
        font-weight: bold;
        letter-spacing: 1px;
        font-size: var(--tablet-normal-font-size);
    }

    .faq-left-heading {
        color: #023273;
        font-size: var(--tablet-heading-font-size2);
        font-weight: bold;
    }

    .faq-left-desc {
        line-height: 24px;
        margin: 20px 0px 0px 0px;
        font-size: var(--tablet-normal-font-size);
    }

    .view-all-faq-button {
        background-color: #023273;
        color: #ffffff;
        /* padding: 10px 20px; */
        border-radius: 10px;
        border: none;
        cursor: pointer;
        font-size: var(--tablet-button-font-size);
        font-weight: bold;
        /* height: 45px; */
        height: 55px;
        width: 55%;
        margin: 70px 0px 0px 0px;
    }

    .expandable-tiles-column {
        /* background-color: red; */
        display: none;
        flex-direction: column;
        height: fit-content;
        width: 100%;
        margin: 50px 0px 0px 0px;
    }

    .expandable-tiles-column-tablet {
        /* background-color: red; */
        display: flex;
        flex-direction: column;
        height: fit-content;
        width: 100%;
        margin: 50px 0px 0px 0px;
    }

    .expandable-tile {
        /* background-color: rosybrown; */
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: fit-content;
        width: 100%;
        border-radius: 8px;
        padding: 18px 0px 18px 0px;
        box-shadow: 0 0 20px #c9c9c95c;
        margin: 12px 0px 0px 0px;
    }

    .expandable-tile-heading {
        /* background-color: palevioletred; */
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 93%;
        cursor: pointer;
    }

    .expandable-tile-heading-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 85%;
        font-weight: bold;
        line-height: 24px;
    }
}

/* Mobile Devices ======================================================================================================================= */
@media only screen and (max-width: 767px) {
    .faq-main-container-tablet {
        /* background-color: red; */
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: fit-content;
        width: 100vw;
        margin: 0px 0px 0px 0px;
    }

    .faq-main-container-desktop {
        display: none;
    }

    .faq-row {
        /* background-color: yellow; */
        display: flex;
        flex-direction: column;
        /* justify-content: space-between; */
        height: fit-content;
        width: 90%;
    }

    .faq-left-side {
        /* background-color: green; */
        width: 100%;
        height: fit-content;
    }

    .faq-right-side {
        /* background-color: green; */
        display: flex;
        flex-direction: column;
        width: 100%;
        height: fit-content;
    }


    .faq-left-faq {
        color: #023273;
        /* font-size: var(--desktop-normal-font-size); */
        font-weight: bold;
        letter-spacing: 1px;
        font-size: var(--mobile-normal-font-size);
    }

    .faq-left-heading {
        color: #023273;
        font-size: var(--mobile-heading-font-size2);
        font-weight: bold;
    }

    .faq-left-desc {
        line-height: 24px;
        margin: 20px 0px 0px 0px;
        font-size: var(--mobile-normal-font-size);
    }

    .view-all-faq-button {
        background-color: #023273;
        color: #ffffff;
        /* padding: 10px 20px; */
        border-radius: 10px;
        border: none;
        cursor: pointer;
        font-size: var(--mobile-button-font-size);
        font-weight: bold;
        /* height: 45px; */
        height: 55px;
        width: 100%;
        margin: 70px 0px 0px 0px;
    }

    .expandable-tiles-column {
        /* background-color: red; */
        display: none;
        flex-direction: column;
        height: fit-content;
        width: 100%;
        margin: 50px 0px 0px 0px;
    }

    .expandable-tiles-column-tablet {
        /* background-color: red; */
        display: flex;
        flex-direction: column;
        height: fit-content;
        width: 100%;
        margin: 50px 0px 0px 0px;
    }

    .expandable-tile {
        /* background-color: rosybrown; */
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: fit-content;
        width: 100%;
        border-radius: 8px;
        padding: 18px 0px 18px 0px;
        box-shadow: 0 0 20px #c9c9c95c;
        margin: 12px 0px 0px 0px;
    }

    .expandable-tile-heading {
        /* background-color: palevioletred; */
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 93%;
        cursor: pointer;
    }

    .expandable-tile-heading-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* cursor: pointer; */
        width: 85%;
        font-weight: bold;
        line-height: 24px;
    }
}

/* Desktops ======================================================================================================================= */
@media only screen and (min-width: 1025px) {
    .faq-main-container-desktop {
        /* background-color: red; */
        display: flex;
        flex-direction: row;
        justify-content: center;
        height: fit-content;
        width: 100vw;
        margin: 0px 0px 0px 0px;
    }

    .faq-main-container-tablet {
        display: none;
    }

    .faq-row {
        /* background-color: yellow; */
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: fit-content;
        width: 89.5%;
    }

    .faq-left-side {
        /* background-color: green; */
        width: 37%;
        height: fit-content;
    }


    .faq-left-faq {
        color: #023273;
        font-size: var(--desktop-normal-font-size);
        font-weight: bold;
        letter-spacing: 1px;
    }

    .faq-left-heading {
        color: #023273;
        font-size: var(--desktop-heading-font-size2);
        font-weight: bold;
    }

    .faq-left-desc {
        line-height: 24px;
        margin: 20px 0px 0px 0px;
        font-size: var(--desktop-normal-font-size);
    }

    .view-all-faq-button {
        background-color: #023273;
        color: #ffffff;
        /* padding: 10px 20px; */
        border-radius: 10px;
        border: none;
        cursor: pointer;
        font-size: var(--desktop-button-font-size);
        font-weight: bold;
        /* height: 45px; */
        height: 55px;
        width: 55%;
        margin: 70px 0px 0px 0px;
    }

    .expandable-tiles-column {
        display: flex;
        flex-direction: column;
        height: fit-content;
        width: fit-content;
    }

    .expandable-tile {
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        height: fit-content;
        width: 45vw;
        border-radius: 8px;
        padding: 18px 18px 18px 18px;
        box-shadow: 0 0 20px #c9c9c95c;
        margin: 12px 0px 0px 0px;
    }

    .expandable-tile-heading {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        cursor: pointer;
    }

    .expandable-tile-heading-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* cursor: pointer; */
        width: 85%;
        font-weight: bold;
        line-height: 24px;
    }
}