@import url('../Constants.css');

/* Tablets ======================================================================================================================= */
@media only screen and (min-width: 768px) and (max-width: 1024px) {

    /* main column that holds the upper text and form....... */
    .compare-form-main-column {
        /* display: black; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0px 0px 0px 0px;
    }

    .compare-title-container {
        /* background-color: red; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        width: 80%;
    }

    .compare-head-column {
        /* background-color: yellow; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        width: 100%;
    }

    .compare-text {
        font-weight: bold;
        letter-spacing: 1px;
        font-size: var(--tablet-normal-font-size);
    }

    .compare-text2 {
        font-weight: bold;
        font-size: var(--tablet-heading-font-size2);
        /* letterSpacing: 1px; */
        margin: 0px 0px 40px 0px;
    }

    .compare-card {
        /* background-color: red; */
        background-color: white;
        border-radius: 8px;
        box-shadow: 0 0 20px #c9c9c95c;
        width: 80%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: start;
        padding: 80px 0px 35px 0px;
    }

    .yes {
        font-size: 30px;
        color: #023273;
    }

    .no {
        font-size: 33.2px;
        color: #bebebe;
    }

    .header-logo-image {
        height: 170%;
        width: 98%;
        margin: 0px 0px 30px -15px;
    }

    .header-title-column {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center
    }

    .left-header-title-text {
        font-weight: bold;
        font-size: 13px;
    }

    .header-title-image {
        height: 20px;
        width: 20px;
        margin: 0px 0px 10px 0px;
    }

    .header-title-text {
        margin: 0px 0px 30px 0px;
        font-weight: bold;
        font-size: 13px;
        text-align: center;
    }

    .c-row {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        height: 80px;
        width: 95%
    }

    .c-row-grey {
        background-color: #fafafa;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        height: 80px;
        width: 95%
    }

    .cell-object {
        height: 100%;
        width: 170px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .cell-object-center {
        height: 100%;
        width: 170px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}


/* Mobile Devices ======================================================================================================================= */
@media only screen and (max-width: 767px) {

    /* main column that holds the upper text and form....... */
    .compare-form-main-column {
        /* display: black; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0px 0px 0px 0px;
    }

    .compare-title-container {
        /* background-color: red; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        width: 89%;
    }

    .compare-head-column {
        /* background-color: yellow; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        width: 100%;
    }

    .compare-text {
        font-weight: bold;
        letter-spacing: 1px;
        font-size: var(--mobile-normal-font-size);
    }

    .compare-text2 {
        font-weight: bold;
        font-size: var(--mobile-heading-font-size2);
        /* letterSpacing: 1px; */
        margin: 0px 0px 40px 0px;
    }

    .compare-card {
        /* background-color: red; */
        background-color: white;
        border-radius: 8px;
        box-shadow: 0 0 20px #c9c9c95c;
        width: 89%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: start;
        padding: 80px 0px 35px 0px;
    }

    .yes {
        font-size: 15px;
        color: #023273;
    }

    .no {
        font-size: 16px;
        color: #bebebe;
    }

    .header-logo-image {
        height: 70%;
        width: 70%;
        margin: 0px 0px 30px 0px;
    }

    .header-title-column {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center
    }

    .left-header-title-text {
        font-weight: bold;
        font-size: 9px;
    }

    .header-title-image {
        height: 20px;
        width: 20px;
        margin: 0px 0px 10px 0px;
    }

    .header-title-text {
        margin: 0px 0px 30px 0px;
        font-weight: bold;
        font-size: 9px;
        text-align: center;
    }

    .c-row {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        height: 80px;
        width: 95%
    }

    .c-row-grey {
        background-color: #fafafa;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        height: 80px;
        width: 95%
    }

    .cell-object {
        height: 100%;
        width: 170px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .cell-object-center {
        height: 100%;
        width: 170px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}


/* Desktops ======================================================================================================================= */
@media only screen and (min-width: 1025px) {

    /* main column that holds the upper text and form....... */
    .compare-form-main-column {
        /* display: black; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0px 0px 0px 0px;
    }

    .compare-title-container {
        /* background-color: red; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        width: 89%;
    }

    .compare-head-column {
        /* background-color: yellow; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        width: 100%;
    }

    .compare-text {
        font-weight: bold;
        letter-spacing: 1px;
        font-size: var(--desktop-normal-font-size);
    }

    .compare-text2 {
        font-weight: bold;
        font-size: var(--desktop-heading-font-size2);
        /* letterSpacing: 1px; */
        margin: 0px 0px 40px 0px;
    }

    .compare-card {
        /* background-color: red; */
        background-color: white;
        border-radius: 8px;
        box-shadow: 0 0 20px #c9c9c95c;
        width: 89%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: start;
        padding: 80px 0px 35px 0px;
    }

    .yes {
        font-size: 35px;
        color: #023273;
    }

    .no {
        font-size: 38px;
        color: #bebebe;
    }

    .header-logo-image {
        height: 155%;
        width: 75%;
        margin: 0px 0px 30px -30px;
    }

    .header-title-column {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center
    }

    .left-header-title-text {
        font-weight: bold;
    }

    .header-title-image {
        height: 30px;
        width: 30px;
        margin: 0px 0px 10px 0px;
    }

    .header-title-text {
        margin: 0px 0px 30px 0px;
        font-weight: bold;
        text-align: center;
    }

    .c-row {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        height: 80px;
        width: 95%
    }

    .c-row-grey {
        background-color: #fafafa;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        height: 80px;
        width: 95%
    }

    .cell-object {
        height: 100%;
        width: 170px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .cell-object-center {
        height: 100%;
        width: 170px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}