@import url('../Constants.css');

/* Tablets ======================================================================================================================= */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .fp-faq-main-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: fit-content;
        width: 100vw;
        margin: 0px 0px 150px 0px;
    }

    .fp-faq-main-heading {
        width: 100%;
        /* background-color: red; */
        display: flex;
        flex-direction: column;
        /* justify-content: space-between; */
        margin: 100px 0px 40px 0px;
    }

    .fp-faq-big-text {
        font-size: var(--tablet-heading-font-size1);
        font-weight: bold;
        color: #023273;
    }

    .fp-faq-small-text {
        /* background-color: green; */
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 70%;
        font-size: var(--tablet-normal-font-size);
        line-height: 24px;
        margin: 30px 0px 0px 0px;
    }

    .fp-faq-row {
        display: flex;
        flex-direction: column;
        height: fit-content;
        width: 80%;
    }

    .fp-faq-right-side {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: fit-content;
    }

    .fp-expandable-tiles-column {
        display: flex;
        flex-direction: column;
        height: fit-content;
        width: 100%;
        margin: 50px 0px 0px 0px;
    }

    .fp-expandable-tile {
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: fit-content;
        width: 100%;
        border-radius: 8px;
        padding: 18px 0px 18px 0px;
        box-shadow: 0 0 20px #c9c9c95c;
        margin: 12px 0px 0px 0px;
    }

    .fp-expandable-tile-heading {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 93%;
        cursor: pointer;
        font-size: var(--tablet-normal-font-size);
    }

    .fp-expandable-tile-heading-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 85%;
        font-weight: bold;
        line-height: 24px;
        font-size: var(--tablet-normal-font-size);
    }

    .fp-dotted-line-row {
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: center;
        height: fit-content;
        width: 100%;
    }

    .fp-dotted-line {
        border-top: 1px dashed #999;
        margin: 8px 0px 8px 0px;
        height: fit-content;
        width: 100%;
    }
}

/* Mobile Devices ======================================================================================================================= */
@media only screen and (max-width: 767px) {
    .fp-faq-main-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: fit-content;
        width: 100vw;
        margin: 0px 0px 150px 0px;
    }

    .fp-faq-main-heading {
        width: 100%;
        /* background-color: red; */
        display: flex;
        flex-direction: column;
        /* justify-content: space-between; */
        margin: 100px 0px 40px 0px;
    }

    .fp-faq-big-text {
        font-size: var(--mobile-heading-font-size1);
        font-weight: bold;
        color: #023273;
    }

    .fp-faq-small-text {
        /* background-color: green; */
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        font-size: var(--mobile-normal-font-size);
        line-height: 24px;
        margin: 30px 0px 0px 0px;
    }

    .fp-faq-row {
        display: flex;
        flex-direction: column;
        height: fit-content;
        width: 90%;
    }

    .fp-faq-right-side {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: fit-content;
    }

    .fp-expandable-tiles-column {
        display: flex;
        flex-direction: column;
        height: fit-content;
        width: 100%;
        margin: 50px 0px 0px 0px;
    }

    .fp-expandable-tile {
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: fit-content;
        width: 100%;
        border-radius: 8px;
        padding: 18px 0px 18px 0px;
        box-shadow: 0 0 20px #c9c9c95c;
        margin: 12px 0px 0px 0px;
    }

    .fp-expandable-tile-heading {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 93%;
        cursor: pointer;
        font-size: var(--mobile-normal-font-size);
    }

    .fp-expandable-tile-heading-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 85%;
        font-weight: bold;
        line-height: 24px;
        font-size: var(--mobile-normal-font-size);
    }

    .fp-dotted-line-row {
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: center;
        height: fit-content;
        width: 100%;
    }

    .fp-dotted-line {
        border-top: 1px dashed #999;
        margin: 8px 0px 8px 0px;
        height: fit-content;
        width: 100%;
    }
}

/* Desktops ======================================================================================================================= */
@media only screen and (min-width: 1025px) {
    .fp-faq-main-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: fit-content;
        width: 100vw;
        margin: 0px 0px 150px 0px;
    }

    .fp-faq-main-heading {
        width: 100%;
        /* background-color: red; */
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 100px 0px 60px 0px;
    }

    .fp-faq-big-text {
        font-size: var(--desktop-heading-font-size1);
        font-weight: bold;
        color: #023273;
    }

    .fp-faq-small-text {
        /* background-color: green; */
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 50%;
        font-size: var(--desktop-normal-font-size);
        line-height: 24px;
    }

    .fp-faq-row {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: fit-content;
        width: 90%;
    }

    .fp-faq-right-side {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 70%;
        height: fit-content;
    }

    .fp-expandable-tiles-column {
        display: flex;
        flex-direction: column;
        height: fit-content;
        width: 100%;
        margin: 50px 0px 0px 0px;
    }

    .fp-expandable-tile {
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: fit-content;
        width: 100%;
        border-radius: 8px;
        padding: 18px 0px 18px 0px;
        box-shadow: 0 0 20px #c9c9c95c;
        margin: 12px 0px 0px 0px;
    }

    .fp-expandable-tile-heading {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 93%;
        cursor: pointer;
        font-size: var(--desktop-normal-font-size);
    }

    .fp-expandable-tile-heading-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 85%;
        font-weight: bold;
        line-height: 24px;
        font-size: var(--desktop-normal-font-size);
    }

    .fp-dotted-line-row {
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: center;
        height: fit-content;
        width: 100%;
    }

    .fp-dotted-line {
        border-top: 1px dashed #999;
        margin: 8px 0px 8px 0px;
        height: fit-content;
        width: 100%;
    }
}