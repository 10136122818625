@import url('../Constants.css');

/* Tablets ======================================================================================================================= */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .navbar-container {
        background-color: white;
        color: white;
        position: fixed;
        top: 0;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: fit-content;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        z-index: 2;
    }

    .navbar-container-in {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 70px;
        width: 80%;
    }

    /* .image-container {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        overflow: hidden;
    } */

    .circular-image {
        width: 60px;
        height: 50px;
    }

    .navbar-left-side {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 100%;
        width: fit-content;
        cursor: pointer;
    }

    .micro-inc-text {
        margin: 0px 0px 0px 20px;
        color: black;
        font-size: 18px;
    }

    .navbar-right-side {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 100%;
    }

    .nav-option {
        display: none;
        margin: 0px 0px 0px 40px;
        color: black
    }

    .transparent-button2 {
        display: none;
        background-color: transparent;
        border: 1px solid black;
        border-radius: 5px;
        color: black;
        padding: 8px 16px;
        cursor: pointer;
        transition: background-color 0.3s, color 0.3s;
        margin: 0px 0px 0px 40px;
        font-size: var(--tablet-button-font-size);
    }

    .hamburger-menu {
        background: none;
        border: none;
        cursor: pointer;
        padding: 10px;
        z-index: 1000;
        margin: 0px 0px 0px 40px;
    }

    .line {
        width: 25px;
        height: 3px;
        background-color: black;
        margin: 5px 0;
        transition: transform 0.3s, opacity 0.3s;
    }

    .line.open:nth-child(1) {
        transform: translateY(8px) rotate(45deg);
    }

    .line.open:nth-child(2) {
        opacity: 0;
    }

    .line.open:nth-child(3) {
        transform: translateY(-8px) rotate(-45deg);
    }

    .nav-menu2,
    .nav-menu2.active {
        display: none;
    }

    .nav-menu {
        position: absolute;
        top: 54px;
        left: 0px;
        /* .................... */
        display: none;
        background-color: white;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        /* height: 250px; */
        margin: 0px 0px 0px 0px;
        padding: 20px 0px 20px 0px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1)
    }

    .nav-menu.active {
        display: flex;
        flex-direction: column;
    }

    .nav-menu-title {
        color: black;
        font-size: var(--mobile-normal-font-size);
        cursor: pointer;
    }

    .nav-menu-title-underlined {
        color: black;
        font-size: var(--mobile-normal-font-size);
        cursor: pointer;
        text-decoration: underline;
        text-underline-offset: 10px;
    }

    .options-menu-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .submenu {
        display: none;
        position: relative;
        background-color: white;
        list-style-type: disc;
        color: black;
        /* z-index: 1; */
    }

    .submenu.active {
        display: flex;
        flex-direction: column;
        /* background-color: red; */
        list-style-type: disc;
        color: black;
    }
}

/* Mobile Devices ======================================================================================================================= */
@media only screen and (max-width: 767px) {
    .navbar-container {
        background-color: white;
        color: white;
        position: fixed;
        top: 0;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: fit-content;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.208);
        z-index: 2;
    }

    .navbar-container-in {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 70px;
        width: 90%;
    }

    /* .image-container {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        overflow: hidden;
    } */

    .circular-image {
        width: 60px;
        height: 50px;
    }

    .navbar-left-side {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 100%;
        width: fit-content;
        cursor: pointer;
    }

    .micro-inc-text {
        margin: 0px 0px 0px 20px;
        color: black;
        font-size: 18px;
    }

    .navbar-right-side {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 100%;
    }

    .nav-option {
        display: none;
        margin: 0px 0px 0px 40px;
        color: black
    }

    .transparent-button2 {
        display: none;
        background-color: transparent;
        border: 1px solid black;
        border-radius: 5px;
        color: black;
        padding: 8px 16px;
        cursor: pointer;
        transition: background-color 0.3s, color 0.3s;
        margin: 0px 0px 0px 40px;
        font-size: var(--mobile-button-font-size);
    }

    .hamburger-menu {
        background: none;
        border: none;
        cursor: pointer;
        padding: 10px;
        z-index: 1000;
        margin: 0px 0px 0px 40px;
    }

    .line {
        width: 25px;
        height: 3px;
        background-color: black;
        margin: 5px 0;
        transition: transform 0.3s, opacity 0.3s;
    }

    .line.open:nth-child(1) {
        transform: translateY(8px) rotate(45deg);
    }

    .line.open:nth-child(2) {
        opacity: 0;
    }

    .line.open:nth-child(3) {
        transform: translateY(-8px) rotate(-45deg);
    }

    .nav-menu2,
    .nav-menu2.active {
        display: none;
    }

    .nav-menu {
        position: absolute;
        top: 54px;
        left: 0px;
        /* .................... */
        display: none;
        background-color: white;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        /* height: 250px; */
        margin: 0px 0px 0px 0px;
        padding: 20px 0px 20px 0px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1)
    }

    .nav-menu.active {
        display: flex;
        flex-direction: column;
    }

    .nav-menu-title {
        color: black;
        font-size: var(--mobile-normal-font-size);
        cursor: pointer;
    }

    .nav-menu-title-underlined {
        color: black;
        font-size: var(--mobile-normal-font-size);
        cursor: pointer;
        text-decoration: underline;
        text-underline-offset: 10px;
    }

    .options-menu-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .submenu {
        display: none;
        position: relative;
        background-color: white;
        list-style-type: disc;
        color: black;
        /* z-index: 1; */
    }

    .submenu.active {
        display: flex;
        flex-direction: column;
        /* background-color: red; */
        list-style-type: disc;
        color: black;
    }
}

/* Desktops ======================================================================================================================= */
@media only screen and (min-width: 1025px) {
    .navbar-container {
        background-color: white;
        color: white;
        position: fixed;
        top: 0;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: fit-content;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1)
    }

    .navbar-container-in {
        /* background-color: red; */
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 70px;
        width: 90%;
    }

    /* .image-container {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        overflow: hidden;
    } */

    .circular-image {
        width: 60px;
        height: 50px;
    }

    .navbar-left-side {
        /* background-color: red; */
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 100%;
        width: fit-content;
        cursor: pointer;
    }

    .micro-inc-text {
        margin: 0px 0px 0px 20px;
        color: black;
        font-size: 18px;
    }

    .navbar-right-side {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 100%;
    }

    .nav-option {
        margin: 0px 0px 0px 40px;
        color: black
    }

    .transparent-button2 {
        background-color: transparent;
        border: 1px solid black;
        border-radius: 5px;
        color: black;
        padding: 8px 16px;
        cursor: pointer;
        transition: background-color 0.3s, color 0.3s;
        margin: 0px 0px 0px 40px;
        font-size: var(--desktop-button-font-size);
    }

    .hamburger-menu {
        display: none;
        border: none;
        cursor: pointer;
        padding: 10px;
        z-index: 1000;
        margin: 0px 0px 0px 40px;
    }

    .line {
        width: 25px;
        height: 3px;
        background-color: black;
        margin: 5px 0;
        transition: transform 0.3s, opacity 0.3s;
    }

    .line.open:nth-child(1) {
        transform: translateY(8px) rotate(45deg);
    }

    .line.open:nth-child(2) {
        opacity: 0;
    }

    .line.open:nth-child(3) {
        transform: translateY(-8px) rotate(-45deg);
    }

    .nav-menu,
    .nav-menu.active {
        display: none;
    }

    .nav-menu2 {
        /* background-color: red; */
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 650px;
    }

    .nav-menu-title {
        color: black;
        font-size: 17px;
        cursor: pointer;
        letter-spacing: 1px;
    }

    .nav-menu-title-underlined {
        color: black;
        font-size: 17px;
        cursor: pointer;
        letter-spacing: 1px;
        text-decoration: underline;
        text-underline-offset: 10px;
    }

    .options-menu-container {
        display: flex;
        /* background-color: red; */
        justify-content: center;
        align-items: center;
        height: 70px;
    }

    .options-menu-container:hover .submenu {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;
    }

    .submenu {
        display: none;
        position: absolute;
        top: 115px;
        background-color: white;
        z-index: 1;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        list-style-type: none;
        color: black;
        /* padding: 25px 25px 25px 25px; */
        padding: 0px 0px 0px 0px;
        margin: 0px 0px 0px 70px;
    }

    .submenu-li {
        width: 100%;
        height: 55px;
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
        padding: 0px 20px 0px 20px;
        color: rgb(102, 102, 102);
    }

    .submenu-li:hover {
        background-color: #edeff7;
        cursor: pointer;
    }
}