@import url('../Constants.css');

/* Tablets ======================================================================================================================= */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .treatments-main-tablet {
        /* background-color: red; */
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        width: 100vw;
        height: fit-content;
        /* height: 800px; */
        margin: 0px 0px 0px 0px;
    }

    .treatments-main-desktop {
        display: none;
    }

    .treatments-main-mobile {
        display: none;
    }

    .treatments-head {
        /* background-color: yellow; */
        display: flex;
        flex-direction: row;
        width: 80%;
        justify-content: start;
        height: fit-content;
    }

    .treatments-head-column {
        /* background-color: green; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: left;
        height: fit-content;
        width: fit-content;
    }

    .treatments-text {
        font-weight: bold;
        letter-spacing: 1px;
        font-size: var(--tablet-normal-font-size);
    }

    .treatments-text2 {
        font-weight: bold;
        font-size: var(--tablet-heading-font-size2);
        /* letterSpacing: 1px; */
        margin: 0px 0px 40px 0px;
    }

    .treatment-cards1 {
        /* background-color: yellowgreen; */
        /* justify-content: space-between; */
        /* display: flex;
        flex-direction: row; */
        width: 100%;
        /* height: fit-content; */
        overflow-x: auto;
        white-space: nowrap;
        margin: 0px 0px 0px 0px;
        scrollbar-width: none
    }

    .tc-space {
        display: inline-block;
        width: 28px;
    }
    
    .treatment-cards2 {
        /* background-color: yellowgreen; */
        display: flex;
        flex-direction: row;
        width: 80%;
        justify-content: space-between;
        height: fit-content;
        margin: 28px 0px 0px 0px;
    }

    .treatment-card-component-container {
        background-color: white;
        display: inline-block;
        margin: 0px 0px 0px 0px;
        border-radius: 8px;
        box-shadow: 0 0 20px #c9c9c95c;
        width: 48%;
        height: 680px;
        cursor: pointer;
    }

    .treatment-card-component-in {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        /* backgroundColor: red */
    }

    .treatment-card-component-images {
        display: flex;
        flex-direction: row;
        height: 45%;
        width: 100%;
        /* backgroundColor: green */
        justify-content: space-between;
    }

    .treatment-card-component-image {
        display: flex;
        flex-direction: column;
        /* backgroundColor: lightBlue, */
        width: 49.5%;
        height: 100%;
    }

    .treatment-card-component-content-area {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 55%;
        width: 100%;
        /* backgroundColor: yellow; */
        padding: 50px 0px 25px 0px
    }

    .treatment-card-component-text0 {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        white-space: normal;
        text-align: left;
        padding: 10px 40px 10px 40px;
        word-spacing: 1px;
        line-height: 24px;
        font-weight: bold;
        font-size: var(--tablet-normal-font-size);
    }

    .treatment-card-component-text1 {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        white-space: normal;
        text-align: left;
        padding: 10px 40px 10px 40px;
        word-spacing: 1px;
        line-height: 24px;
        font-size: var(--tablet-normal-font-size);
    }

    .treatment-card-component-text2 {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        white-space: normal;
        text-align: left;
        padding: 10px 40px 10px 40px;
        font-size: var(--tablet-normal-font-size);
        text-decoration: underline;
    }
}

/* Mobile Devices ======================================================================================================================= */
@media only screen and (max-width: 767px) {
    .treatments-main-mobile {
        /* background-color: red; */
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        width: 100vw;
        height: fit-content;
        /* height: 800px; */
        margin: 0px 0px 0px 0px;
    }

    .treatments-main-tablet {
        display: none;
    }

    .treatments-main-desktop {
        display: none;
    }

    .treatments-head {
        /* background-color: yellow; */
        display: flex;
        flex-direction: row;
        width: 90%;
        justify-content: start;
        height: fit-content;
    }

    .treatments-head-column {
        /* background-color: green; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: left;
        height: fit-content;
        width: fit-content;
    }

    .treatments-text {
        font-weight: bold;
        letter-spacing: 1px;
        font-size: var(--mobile-normal-font-size);
    }

    .treatments-text2 {
        font-weight: bold;
        font-size: var(--mobile-heading-font-size2);
        /* letterSpacing: 1px; */
        margin: 0px 0px 40px 0px;
    }

    .treatment-cards {
        /* background-color: yellowgreen; */
        /* justify-content: space-between; */
        /* display: flex;
        flex-direction: row; */
        width: 100%;
        /* height: fit-content; */
        overflow-x: auto;
        white-space: nowrap;
        margin: 0px 0px 0px 0px;
        scrollbar-width: none
    }

    .tc-space {
        display: inline-block;
        width: 28px;
    }

    .treatment-card-component-container {
        background-color: white;
        display: inline-block;
        margin: 0px 0px 0px 0px;
        border-radius: 8px;
        box-shadow: 0 0 20px #c9c9c95c;
        width: 100%;
        height: 630px;
        margin: 0px 0px 0px 0px;
        cursor: pointer;
    }

    .treatment-card-component-in {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        /* backgroundColor: red */
    }

    .treatment-card-component-images {
        display: flex;
        flex-direction: row;
        height: 45%;
        width: 100%;
        /* backgroundColor: green */
        justify-content: space-between;
    }

    .treatment-card-component-image {
        display: flex;
        flex-direction: column;
        /* backgroundColor: lightBlue, */
        width: 49.5%;
        height: 100%;
    }

    .treatment-card-component-content-area {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 55%;
        width: 100%;
        /* backgroundColor: yellow; */
        padding: 50px 0px 25px 0px
    }

    .treatment-card-component-text0 {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        white-space: normal;
        text-align: left;
        padding: 10px 40px 10px 40px;
        word-spacing: 1px;
        line-height: 24px;
        font-weight: bold;
        font-size: 20px;
    }

    .treatment-card-component-text1 {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        white-space: normal;
        text-align: left;
        padding: 10px 40px 10px 40px;
        word-spacing: 1px;
        line-height: 24px;
        font-size: var(--mobile-normal-font-size);
    }

    .treatment-card-component-text2 {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        white-space: normal;
        text-align: left;
        padding: 10px 40px 10px 40px;
        font-size: var(--mobile-normal-font-size);
        text-decoration: underline;
    }
}

/* Desktops ======================================================================================================================= */
@media only screen and (min-width: 1025px) {

    .treatments-main-desktop {
        /* background-color: red; */
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        width: 100vw;
        height: fit-content;
        /* height: 800px; */
        margin: 0px 0px 0px 0px;
    }

    .treatments-main-tablet {
        display: none;
    }

    .treatments-main-mobile {
        display: none;
    }

    .treatments-head {
        /* background-color: yellow; */
        display: flex;
        flex-direction: row;
        width: 90%;
        justify-content: space-between;
        align-items: end;
        height: fit-content;
    }

    .treatments-head-column {
        /* background-color: green; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: left;
        height: fit-content;
        width: fit-content;
    }

    .treatments-text {
        font-weight: bold;
        letter-spacing: 1px;
        font-size: var(--desktop-normal-font-size);
    }

    .treatments-text2 {
        font-weight: bold;
        font-size: var(--desktop-heading-font-size2);
        /* letterSpacing: 1px; */
        margin: 0px 0px 0px 0px;
    }

    .treatment-cards {
        /* background-color: yellowgreen; */
        /* justify-content: space-between; */
        /* display: flex;
        flex-direction: row; */
        width: 90%;
        /* height: fit-content; */
        overflow-x: auto;
        white-space: nowrap;
        margin: 0px 0px 0px 0px;
        scrollbar-width: none
    }

    .tc-space {
        display: inline-block;
        width: 28px;
    }


    .treatment-card-component-container {
        background-color: white;
        display: inline-block;
        margin: 0px 0px 0px 0px;
        border-radius: 8px;
        box-shadow: 0 0 20px #c9c9c95c;
        width: 347px;
        height: 620px;
        margin: 40px 5px 0px 5px;
        cursor: pointer;
    }

    .treatment-card-component-in {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        /* backgroundColor: red */
    }

    .treatment-card-component-images {
        display: flex;
        flex-direction: row;
        height: 45%;
        width: 100%;
        /* backgroundColor: green */
        justify-content: space-between;
    }

    .treatment-card-component-image {
        display: flex;
        flex-direction: column;
        /* backgroundColor: lightBlue, */
        width: 49.5%;
        height: 280px;
    }

    .treatment-card-component-content-area {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 55%;
        width: 100%;
        /* backgroundColor: yellow; */
        padding: 10px 0px 25px 0px
    }

    .treatment-card-component-text0 {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        white-space: normal;
        text-align: left;
        padding: 10px 40px 10px 40px;
        word-spacing: 1px;
        line-height: 24px;
        font-weight: bold;
        font-size: 18px;
    }

    .treatment-card-component-text1 {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        white-space: normal;
        text-align: left;
        padding: 10px 40px 10px 40px;
        word-spacing: 1px;
        line-height: 24px;
        font-size: var(--desktop-normal-font-size);
    }

    .treatment-card-component-text2 {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        white-space: normal;
        text-align: left;
        padding: 10px 40px 10px 40px;
        font-size: var(--desktop-normal-font-size);
        text-decoration: underline;
    }
}